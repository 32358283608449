
import "./radio-v1"
import "./survey-image-option-v1"
import "./checkbox-v1"
import "./input-v1"
import { IntlTel_initPhoneInput } from 'javascript/lander/cf_utils'
import { resetInputErrors, addError, validateValue } from '@yggdrasil/Checkout/V2/Utils/checkout-input-validator'
import { CF2Component } from 'javascript/lander/runtime'

export default class SurveyV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}




  mount() {
    if (!this.workflow_id) {
      return
    }

    this.SurveyStates = {
      FINISHED: 'finished',
      IN_PROGRESS: 'in-progress',
      ERROR: 'error',
    }

    this.contactInputNames = {
      name: {
        label: 'Name',
        inputType: 'text',
      },
      first_name: {
        label: 'First Name',
        inputType: 'text',
        autocomplete: 'given_name'
      },
      last_name: {
        label: 'Last Name',
        inputType: 'text',
        autocomplete: 'family_name'
      },
      email: {
        label: 'Email',
        inputType: 'email',
        autocomplete: 'email'
      },
      phone_number: {
        label: 'Phone Number',
        inputType: 'tel',
        autocomplete: 'tel-national'
      },
    }

    this.allQuestions = []
    this.allResponses = []
    this.survey_question_pos = 0
    this.renderCurrentSurveyStep()
  }

  markSelectedAnswers() {
    if(!this.element.querySelector('.elSurveyModern')) return
    const answers = this.element.querySelectorAll('.elSurveyItem > div')

    answers.forEach((answer) => {
      const answerInput = answer.querySelector('input')

      if(answerInput.checked) {
        answer.setAttribute('data-checked', 'true')
      }

      answer.addEventListener('click', (evt) => {
        answers.forEach((answer)=>{
          const answerInput = answer.querySelector('input')

          if(answerInput.checked){
            answer.setAttribute('data-checked', 'true')
          } else {
            answer.setAttribute('data-checked', 'false')
          }
        })
      })
    })
  }

  getPathName() {
    let pathName = window.location.pathname
    if (pathName.charAt(pathName.length - 1) === '/') {
      pathName = pathName.slice(0, -1)
    }
    return pathName
  }

  renderCurrentSurveyStep() {
    this.getSurveyNextButtonContainer().style.display = 'none'
    const fetchCurrentStepUrl = this.getPathName() + `/cf_survey?workflow_id=${this.workflow_id}`
    fetch(fetchCurrentStepUrl,

          {
            credentials: "same-origin",
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'GET',
          }
        )
      .then((res) => res.json())
      .then((res) => {
        this.renderResponse(res)
      }).finally(() => {
        delete this.getInitialSpinner().dataset.loader
        this.initialLoad = false
        if (this.getSurveyNextButtonContainer()) {
          this.getSurveyNextButtonContainer().style.display = 'block'
        }
      })

  }

  renderCurrentQuestion() {
    this.preProcessQuestionSteps()
    this.allQuestions.splice(this.survey_question_pos, 1, this.survey_questions)
    const previousResponses = this.allResponses[this.survey_question_pos]

    this.survey_questions.fields.forEach((field, indexQuestion) => {
      if (field.attribute == 'phone_number') {
        window.setTimeout(() => this.initializePhoneNumber(field.id), 10)
      }
      if (previousResponses) {
        field.options.forEach((option, optionIndex) => {
          const isChecked = previousResponses[field.id].some((previousResp) => previousResp == option.id)
          field.options[optionIndex].checked = isChecked
        })
      }
    })

    this.render(true)
    this.addEventListeners()
    this.setupOtherOption()
    this.markSelectedAnswers()
  }

  preProcessQuestionSteps() {
    this.survey_questions.fields.forEach((field) => {
      if (field.type == 'text') {
        let label, inputType, autocomplete
        if (this.contactInputNames[field.attribute]) {
          const inputDetails = this.contactInputNames[field.attribute]
          label = inputDetails.label
          inputType = inputDetails.inputType
          autocomplete = inputDetails.autocomplete
        } else if (field.attribute) {
          const processedLabel = field.attribute.split("_").join(" ")
          label = processedLabel.charAt(0).toUpperCase() + processedLabel.slice(1)
          inputType = "text"
          autocomplete = field.attribute
        }
        field.input_label = label ?? ""
        field.input_type = inputType ?? ""
        field.autocomplete = autocomplete ?? false
        field.label_type = field.attribute ? 'inside' : 'on-border'
      }
    })
  }

  updateLoader(isLoading) {
    if (this.survey_status == this.SurveyStates.FINISHED) return

    if (isLoading == undefined) {
      isLoading = this.lastLoading
    } else {
      this.lastLoading = isLoading
    }

    this.getSurveyNextButtonContainer().dataset.loading = isLoading
    this.getSurveyNextButtonLink().setAttribute("data-disabled", isLoading)
  }

  getSurveyNextButtonContainer() {
    return this.element.querySelector('.elSurveyButtonNext')
  }

  getSurveyNextButtonLink() {
    return this.element.querySelector('.elSurveyButtonNext a')
  }

  getInitialSpinner() {
    return this.element.querySelector('.elSpinnerWrapper')
  }

  getSurveyContactInput() {
    return this.element.querySelector('.elSurveyContactInput')
  }

  getSurveyErrorContainer() {
    return this.element.querySelector('.elError')
  }

  updateProgress(progressResponse) {
    if (progressResponse) {
      const { current_step, total_steps } = progressResponse
      const calculatedProgress = (current_step / total_steps) * 100
      // clamps value min -> 5 + current step, max -> 99
      this.survey_progress = Math.min(Math.max(5 + current_step, calculatedProgress), 99)
    } else if (this.survey_status == this.SurveyStates.FINISHED) {
      this.survey_progress = 100
    }
  }

  updateStatus(response) {
    this.survey_status = response.status
    if (response.redirect_to) {
      this.redirect_url = response.redirect_to
    }
  }

  postResponse(answers) {
    this.updateLoader(true)
    const reqResponse = {
      workflow_id: this.workflow_id,
      answers: Object.keys(answers).reduce((acc, fieldId) => {
        if (Array.isArray(answers[fieldId])) {
          let entry = null
          let selectedOptions = []
          answers[fieldId].forEach(answer => {
            if (typeof answer == 'string') {
              selectedOptions.push(answer)
            } else {
              entry = answer.otherOption
            }
          })
          acc.push({
            field_id: fieldId,
            selected_options: selectedOptions,
            entry: entry,
          })
        } else {
          acc.push({
            field_id: fieldId,
            entry: answers[fieldId],
          })
        }
        return acc
      }, [])
    }

    fetch(this.getPathName() + '/cf_survey', {
      credentials: "same-origin",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(reqResponse)
    }
        )
      .then((res) => {
        this.hasError = false
        if (res.status >= 500) {
          console.error(res)
          throw Error(`Something went wrong, error status: #{res.status}`)
        } else if (res.status >= 400) {
          this.hasError = true
        }
        return res.json()
      })
      .then((res) => {
        if (this.hasError) {
          this.renderErrorMessage(res.message.join(', '))
        } else {
          this.allResponses.splice(this.survey_question_pos, 1, answers)
          this.survey_question_pos++
          this.renderResponse(res)
        }
      }).finally(() => {
        this.updateLoader(false)
      })
  }

  renderResponse(response) {
    this.updateStatus(response)
    this.updateProgress(response.progress)

    switch (this.survey_status) {
    case this.SurveyStates.FINISHED: {
      if (this.redirect_url) {
        window.location = this.redirect_url
      } else {
        if (this.survey_end_href) {
          const fakeButton = document.createElement("a")
          fakeButton.style.display = "none";
          fakeButton.href = this.survey_end_href
          fakeButton.target = this.target
          if (this.survey_end_href == '#show-hide') {
            fakeButton.dataset.showButtonIds = this.showIds
            fakeButton.dataset.hideButtonIds = this.hideIds
          }
          const nextButton = this.getSurveyNextButtonLink()
          nextButton.parentNode.insertBefore(fakeButton, nextButton)
          fakeButton.click()
          fakeButton.remove()
        }

        if (!this.survey_end_href || ['#', '#open-popup', '#close-popup', '#scroll-', '#show-hide'].includes(this.survey_end_href)) {
          this.render(true)
        }
      }
    } break;
    default: {
      this.survey_questions = response
      this.renderCurrentQuestion()
    }
    }
  }

  renderErrorMessage(errorMessage) {
    const input = this.getSurveyContactInput()
    if (input) {
      resetInputErrors(input)
      addError(input, errorMessage)
    } else {
      this.getSurveyErrorContainer().innerHTML = errorMessage
    }
  }

  resetErrorMessage() {
    const input = this.getSurveyContactInput()
    if (input) {
      resetInputErrors(input)
    } else {
      this.getSurveyErrorContainer().innerHTML = ""
    }
  }

  addEventListeners() {
    if (this.survey_status == this.SurveyStates.FINISHED) return
    this.element.querySelector('.elButton[href="#previous"]')?.addEventListener('click', () => {
      this.survey_question_pos--
      this.survey_questions = this.allQuestions[this.survey_question_pos]
      this.renderCurrentQuestion()
    })

    const handleFormSubmission = () => {
      const form = this.element.querySelector('form')
      const formData = new FormData(form)
      const currentFields = this.allQuestions[this.survey_question_pos].fields
      const answers = [...formData].reduce((acc, [key, value]) => {
        const field = currentFields.find(field => field.id == key)
        if (field.type == "text") {
          const input = this.element.querySelector(`input[name="${field.id}"].elSurveyContactInput`)
          if (field.attribute == "phone_number" && input.iti) {
            acc[key] = input.iti.getNumber()
          } else {
            acc[key] = value
          }
        } else {
          const allCurrentOptions = this.survey_questions.fields.reduce((acc, field) => {
            field.options.forEach(opt => acc.push(opt.id))

            return acc
          }, [])

          acc[key] = acc[key] ?? []

          if (allCurrentOptions.includes(value)) {
            acc[key].push(value)
          } else {
            acc[key].push({ otherOption: value })
          }
        }
        return acc
      }, {})

      if (this.validAnswers(answers)) {
        this.postResponse(answers)
      }
    }

    this.element.querySelector('.elButton[href="#next"]').addEventListener('click', handleFormSubmission)

    const controller = new AbortController()

    document.addEventListener('keydown', (event) => {
      if (event.key === "Enter") {
        event.preventDefault()

        handleFormSubmission()
        controller.abort()
      }
    }, { signal: controller.signal })

    this.element.querySelector('input[type="checkbox"],input[type="radio"]')?.addEventListener('click', () => {
      this.resetErrorMessage()
    })
  }

  setupOtherOption() {
    const otherOptionController = new AbortController()
    this.otherChoice = this.element.querySelector('.elSurveyOtherInputChoice')

    const checkboxOrRadioChildElement = this.otherChoice.querySelector('span')

    const checkboxOrRadioInputElement = this.otherChoice.querySelector('.elRadioInput, .elCheckboxInput')

    const dynamicOtherInputElement = document.createElement('input')
    dynamicOtherInputElement.type = 'text'
    dynamicOtherInputElement.classList.add('elSurveyOtherInputField')

    const defaultValue = 'Other'

    if (this.otherChoice) {
      this.otherChoice.addEventListener('click', () => {
        checkboxOrRadioChildElement.parentNode.replaceChild(dynamicOtherInputElement, checkboxOrRadioChildElement)

        this.otherChoice.classList.add('elSurveyOtherFocused')

        dynamicOtherInputElement.focus()
      })

      dynamicOtherInputElement.addEventListener("focusout", (e) => {
        e.target.blur()

        this.otherChoice.classList.remove('elSurveyOtherFocused')
        const inputFieldElement = this.otherChoice.querySelector('.elSurveyOtherInputField')

        if (inputFieldElement.value == '' || inputFieldElement.value == undefined) {
          inputFieldElement.value == ''
          inputFieldElement.parentNode.replaceChild(checkboxOrRadioChildElement, inputFieldElement)
        } else {
          inputFieldElement.parentNode.replaceChild(checkboxOrRadioChildElement, inputFieldElement)
          checkboxOrRadioInputElement.value = inputFieldElement.value
          checkboxOrRadioChildElement.textContent = inputFieldElement.value
          checkboxOrRadioChildElement.classList.add('otherOptionProvided')
        }
      })
    }
  }

  // Client side validations
  validAnswers(answers) {
    let isValid = true

    this.survey_questions.fields.forEach((field) => {
      if (field.attribute) {
        isValid = this.validateCustomAttribute(field, answers)
      } else if (field.type == "choice" || field.type == "image_choice") {
        isValid = this.validateChoiceType(field, answers)
      } else {
        isValid = true // this.validateDefaultType(field, answers)
      }
    })

    return isValid
  }

  validateCustomAttribute(field, answers) {
    let isValid = true
    const input = this.element.querySelector(`input[name="${field.id}"].elSurveyContactInput`)

    const validationResponse = validateValue(field.attribute, answers[field.id])
    if (!validationResponse.valid) {
      addError(input, validationResponse.message)
      isValid = false
    }

    return isValid
  }

  validateChoiceType(field, answers) {
    let isValid = true

    if (field.allow_multiple) {
      if (Object.entries(answers).length < 1) {
        this.renderErrorMessage("Please select at least one of the options")
        isValid = false
      }
    } else {

      if (Object.entries(answers).length != 1) {
        this.renderErrorMessage("Please select one of the options")
        isValid = false
      }
    }

    if (this.survey_questions.configuration.allow_other_choice) {
      Object.values(answers).flat().forEach((ans) =>  {
        if (typeof ans == 'object' && ans.otherOption.length > 120) {
          this.renderErrorMessage("Provided answer is too long.")
          isValid = false
        }
      })
    }

    return isValid
  }

  // TODO: This can be configured later to allow a user to decide if a question
  // is required
  // validateDefaultType(_field, answers) {
    // let isValid = true
    // if (Object.values(answers).filter(Boolean).length == 0) {
    //   isValid = false
    //   this.renderErrorMessage("Please provide an answer.")
    // }
    //
    // return isValid
    //   }


  initializePhoneNumber(fieldId) {
    const phoneInput = this.element.querySelector(`input[name="${fieldId}"].elSurveyContactInput`)
    phoneInput && IntlTel_initPhoneInput(phoneInput)
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const design_style = this.design_style ?? null ;
const workflow_id = this.workflow_id ?? null ;
const survey_end_href = this.survey_end_href ?? null ;
const target = this.target ?? null ;
const showIds = this.showIds ?? null ;
const hideIds = this.hideIds ?? null ;
const survey_questions = this.survey_questions ?? null ;
const survey_status = this.survey_status ?? null ;
const survey_progress = this.survey_progress ?? 0 ;
const survey_progress_enabled = this.survey_progress_enabled ?? true ;
const survey_question_pos = this.survey_question_pos ?? 0 ;
const survey_end_text = this.survey_end_text ?? null ;
const initialLoad = this.initialLoad ?? true ;

    const buttonState = this.element.getAttribute('data-param-buttonState');
  
      let html = '';
      {
        html += `<div data-page-element="Spinner/V1" class="elSpinnerWrapper layer-behind-modal-backdrop id-Spinner/V1" data-loader="${initialLoad}"><div class="elSpinner"></div></div>`
        let showInput = true;
        if (design_style == "modern") {
          showInput = false;
        }
        if (survey_status && survey_status == "finished") {
          html += `<div data-page-element="Headline/V1" class="elHeadlineWrapper elSurveyHeadline id-Headline/V1"><h1 class="elHeadline"><span>${survey_end_text}</span></h1></div>`
          if (survey_progress_enabled) {
            html += `<div data-page-element="ProgressBar/V1" class="elProgressBar id-ProgressBar/V1" data-liquid-replace="item"><p class="progress-label hide"></p><div class="progress"><div style="width:100%;" class="progress-bar" role="progressbar"><p class="progress-label"></p></div></div></div>`
          }
        } else {
          html += `<form>`
          if (survey_questions.question_step_type == "contact_info") {
            html += `<div data-page-element="Headline/V1" class="elHeadlineWrapper elSurveyQuestionTitle id-Headline/V1"><h1 class="elHeadline"><span>${survey_questions.question_step_title}</span></h1></div>`
            html += `<div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elSurveyQuestionDescription id-SubHeadline/V1"><h2 class="elSubheadline"><span>${survey_questions.description}</span></h2></div>`
          }
          if (survey_progress_enabled) {
            if (survey_questions.question_step_type == "contact_info") {
              html += `<div data-page-element="ProgressBar/V1" class="elProgressBar id-ProgressBar/V1" data-liquid-replace="item"><p class="progress-label hide"></p><div class="progress"><div style="width:${survey_progress}%;" class="progress-bar" role="progressbar"><p class="progress-label"></p></div></div></div>`
            }
          }
          const c0 = survey_questions.fields
          const fl1 = new CF2ForloopDrop(c0.length)
          for (const survey_question_field of c0) {
            const forloop = fl1
            html += `<div class="elSurveyQuestion"><div data-page-element="Headline/V1" class="elHeadlineWrapper elSurveyFieldLabel id-Headline/V1`
            if (survey_questions.question_step_type == "contact_info") {
              html += ` elSurveyCollection`
            }
            html += `"><h1 class="elHeadline"><span>${survey_question_field.label}</span></h1></div>`
            if (!!survey_question_field.field_description) {
              html += `<div data-page-element="SubHeadline/V1" class="elSubheadlineWrapper elSurveyFieldDescription id-SubHeadline/V1`
              if (survey_questions.question_step_type == "contact_info") {
                html += ` elSurveyCollection`
              }
              html += `"><h2 class="elSubheadline"><span>${survey_question_field.field_description}</span></h2></div>`
            }
            if (survey_progress_enabled) {
              if (survey_questions.question_step_type != "contact_info") {
                html += `<div data-page-element="ProgressBar/V1" class="elProgressBar id-ProgressBar/V1" data-liquid-replace="item"><p class="progress-label hide"></p><div class="progress"><div style="width:${survey_progress}%;" class="progress-bar" role="progressbar"><p class="progress-label"></p></div></div></div>`
              }
            }
            html += `<div class="elSurveyList"><div data-option-image-size="${survey_questions.configuration.option_image_size}" class="`
            if (survey_question_field.type == "image_choice") {
              html += ` elOptionGrid`
            }
            if (design_style == "modern") {
              html += ` elSurveyModern`
            }
            html += `">`
            if (survey_question_field.type == "text") {
              let input_label = survey_question_field.input_label ?? "";
              let initialValue = "";
              html += `<div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block id-Input/V1`
              if (survey_question_field.label_type == "inside") {
                html += ` labelInside`
              }
              if (survey_question_field.label_type == "on-border") {
                html += ` labelOnBorder`
              }
              html += `" type="${survey_question_field.id}" data-liquid-replace="item">`
              if (survey_question_field.id == "phone_number") {
              }
              html += `<input class="elFormItem elInput inputHolder elSurveyContactInput" value="${initialValue}" type="${survey_question_field.input_type}" name="${survey_question_field.id}" pattern="" autocomplete="${survey_question_field.autocomplete}" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="${input_label}" maxlength=""/>`
              if (survey_question_field.label_type != "none") {
                html += `<div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>${input_label}</label></div><div class="borderHolder"></div></div>`
              }
              html += `<div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div>`
            } else {
              const c2 = survey_question_field.options
              const fl3 = new CF2ForloopDrop(c2.length)
              for (const option of c2) {
                const forloop = fl3
                html += `<div class="elSurveyItem">`
                if (survey_question_field.type == "image_choice") {
                  html += `<div data-page-element="SurveyImageOption/V1" class="elSurveyImageOptionBase id-SurveyImageOption/V1" data-checked="${option.checked}">`
                  let input_label = "";
                  let alt_text = "";
                  if (!!option.image_alt_text) {
                    alt_text = option.image_alt_text;
                  } else {
                    if (!!option.label) {
                      alt_text = option.label;
                    } else {
                      alt_text = survey_question_field.id;
                    }
                  }
                  if (survey_questions.configuration.show_labels) {
                    input_label = option.label;
                  }
                  html += `<div class="elSurveyImageOptionBase__Image"><img src="${option.image}" alt="${alt_text}"/></div><div class="elSurveyImageOptionBase__Input">`
                  if (survey_question_field.allow_multiple) {
                    html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="true" data-checked="${option.checked}"><label class="elCheckboxLabel"><input type="checkbox" value="${option.id}" name="${survey_question_field.id}" `
                    if (option.checked) {
                      html += `checked`
                    }
                    html += ` data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div>`
                    if (input_label) {
                      html += `<span class="elCheckboxText">${input_label}</span>`
                    } else {
                      html += `<span class="elCheckboxText"></span>`
                    }
                    html += `</label><div data-input-status-type="true" data-error-container="true"></div></div>`
                  } else {
                    html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="${option.checked}" data-trigger-click-on-wrapper="true"><label class="elRadioLabel"><input type="radio" value="${option.id}" name="${survey_question_field.id}" `
                    if (option.checked) {
                      html += `checked`
                    }
                    html += ` class="elRadioInput"/><div class="elRadio"><div class="elRadioIcon"></div></div>`
                    if (input_label) {
                      html += `<span class="elRadioText">${input_label}</span>`
                    } else {
                      html += `<span class="elRadioText"></span>`
                    }
                    html += `</label></div>`
                  }
                  html += `</div></div>`
                } else if (survey_question_field.allow_multiple) {
                  html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="true" data-checked="${option.checked}"><label class="elCheckboxLabel"><input type="checkbox" value="${option.id}" name="${survey_question_field.id}" `
                  if (option.checked) {
                    html += `checked`
                  }
                  html += ` data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox`
                  if (showInput == false) {
                    html += ` elHide`
                  }
                  html += `"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div>`
                  if (option.label) {
                    html += `<span class="elCheckboxText">${option.label}</span>`
                  } else {
                    html += `<span class="elCheckboxText"></span>`
                  }
                  html += `</label><div data-input-status-type="true" data-error-container="true"></div></div>`
                } else {
                  if (option.checked) {
                    html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="true" data-trigger-click-on-wrapper="true"><label class="elRadioLabel"><input type="radio" value="${option.id}" name="${survey_question_field.id}" checked class="elRadioInput"/><div class="elRadio`
                    if (showInput == false) {
                      html += ` elHide`
                    }
                    html += `"><div class="elRadioIcon"></div></div>`
                    if (option.label) {
                      html += `<span class="elRadioText">${option.label}</span>`
                    } else {
                      html += `<span class="elRadioText"></span>`
                    }
                    html += `</label></div>`
                  } else {
                    html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="true"><label class="elRadioLabel"><input type="radio" value="${option.id}" name="${survey_question_field.id}"  class="elRadioInput"/><div class="elRadio`
                    if (showInput == false) {
                      html += ` elHide`
                    }
                    html += `"><div class="elRadioIcon"></div></div>`
                    if (option.label) {
                      html += `<span class="elRadioText">${option.label}</span>`
                    } else {
                      html += `<span class="elRadioText"></span>`
                    }
                    html += `</label></div>`
                  }
                }
                html += `</div>`
                forloop.next();
              }
              if (survey_questions.question_step_type == "choice") {
                if (survey_questions.configuration.allow_other_choice) {
                  html += `<div class="elSurveyItem elSurveyOtherInputChoice">`
                  if (survey_question_field.allow_multiple) {
                    html += `<div data-page-element="Checkbox/V1" type="" class="elCheckboxWrapper elFormItemWrapper de-input-block id-Checkbox/V1" data-liquid-replace="item" data-prevent-click-on-label="false" data-trigger-click-on-wrapper="true" data-checked="false"><label class="elCheckboxLabel"><input type="checkbox" value="" name="${survey_question_field.id}"  data-prevent-garlic="false" class="elCheckboxInput inputHolder"/><div class="elCheckbox`
                    if (showInput == false) {
                      html += ` elHide`
                    }
                    html += `"><div class="elCheckboxIcon"><div class="elCheckboxIconSquare hide"></div><i class="elCheckboxIconCheck fas fa-check"></i></div></div><span class="elCheckboxText">Other</span></label><div data-input-status-type="true" data-error-container="true"></div></div>`
                  } else {
                    html += `<div data-page-element="Radio/V1" class="elRadioWrapper id-Radio/V1" data-liquid-replace="item" data-checked="" data-trigger-click-on-wrapper="true"><label class="elRadioLabel"><input type="radio" value="" name="${survey_question_field.id}"  class="elRadioInput"/><div class="elRadio`
                    if (showInput == false) {
                      html += ` elHide`
                    }
                    html += `"><div class="elRadioIcon"></div></div><span class="elRadioText">Other</span></label></div>`
                  }
                  html += `</div>`
                }
              }
            }
            html += `<div class="elError"></div></div></div></div>`
            forloop.next();
          }
          html += `</form>`
          html += `<div class="elSurveyButtonWrapper"><div data-page-element="Button/V1" class="elBTN elSurveyButton elSurveyButtonNext id-Button/V1`
          if (survey_question_pos == 0) {
            html += ` elSurveyButtonRightAligned`
          }
          html += `" data-liquid-replace="item"><a target="_self" href="#next" class="elButton`
          if (buttonState == "hover") {
            html += ` elButtonHovered`
          }
          if (buttonState == "focused") {
            html += ` elButtonActive`
          }
          html += `" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Next</span></span><span class="elButtonSub"></span></a></div><span class="elSurveyButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div>`
          html += `<div style="clear:both;"></div>`
        }

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["SurveyV1"] = SurveyV1

